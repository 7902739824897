import React from 'react';
import BannerSection from '../components/AccountAgreement/BannerSection';
import Layout from '../components/Layout/Layout';
import TermsContentSection from '../components/Terms/TermsContentSection';

const pageInfo = {
  path: '/terms',
  title: 'Terms and Conditions of Use',
  description:
    "By using the Site, which is part of the systems and services we provide, or by making a donation through our parts of our systems, you are agreeing to be bound by these terms and conditions ('Terms of Use') and all applicable laws and regulations.",
  isNavbar: false,
  isFooter: false,
};

const Terms = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection bannerText='Terms and Conditions of Use' />
      <TermsContentSection />
    </Layout>
  );
};

export default Terms;
